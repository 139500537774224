import { Pipe, PipeTransform } from "@angular/core";
import { ComponentService } from '../service/component.service';

@Pipe({
    name: 'tr',
    pure: false
})
export class TranslatePipe implements PipeTransform {

    constructor(private cs: ComponentService) {}

    transform(key: string, defaultVal?: string): string {
        let label = this.cs.getLabel(key);
        return label?label:(defaultVal?defaultVal:'');
    }
}