import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { BaseComponent } from './base.component';
import { ChangeLanguage } from './labels/labels.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent {
  title = 'borneo3d';
  tagline = 'VIRTUALIZING YOUR WORLD';
  homeDesc = `3D virtualization for landscapes and buildings, AR/VR implementations, and so on.`;
  featured = 'Featured Projects';

  year = (new Date()).getFullYear();

  langs = [
    {key:'en_US', value:'English'},
    {key:'zh_Hans', value:'简体中文'},
    {key:'ms', value:'Bahasa Melayu'}
  ]

  @ViewChild('tagline') taglineDiv: ElementRef;

  constructor(private store: Store, public router: Router) {super()}

  ngOnInit() {
    super.ngOnInit();
    setInterval(() => {this.taglineDiv.nativeElement.style.display = 'none'},10000);
  }

  

  changeLang(lang: string) {
    this.store.dispatch(new ChangeLanguage(lang));
  }

  openWindow(url: string) {
    window.open(url);
  }

  ngOnDestroy() {super.ngOnDestroy()}

  
}
