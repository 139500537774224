import { NgModule } from "@angular/core";

import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import {MatGridListModule} from '@angular/material/grid-list';

@NgModule({
    imports: [MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatGridListModule
    ],
    exports: [MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatGridListModule
    ]
})
export class MaterialModule {}