<div>
    <div class="webgl-content" id="unity-container">
        <canvas id="unity-canvas"  class="unity-desktop"></canvas>
        <div id="unity-loading-bar">
          <div id="unity-progress-bar-empty">
              <div id="unity-progress-bar-full"><div>{{'loading' | tr:'Loading'}}</div></div>
          </div>
        </div>
        <div id="unity-footer" style="text-align: end;">
            <button mat-button (click)="reload()">B3DROOM</button>
            <button mat-icon-button id="unity-fullscreen-button" title="{{'fullscreen' | tr}}"><mat-icon>open_in_full</mat-icon></button>
            <button mat-icon-button id="unity-end-button" [routerLink]="['/']" title="{{'quit' | tr}}" ><mat-icon>highlight_off</mat-icon></button>
        </div>
    </div>

</div>