import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { ModelModule } from './model/model.module';
import { StateModule } from './state.module';
import { B3droomComponent } from './b3droom/b3droom.component';
import { ComponentService } from './service/component.service';
import { TranslatePipe } from './labels/translate.pipe';
import { PipesModule } from './pipes.module';

@NgModule({
  declarations: [
    AppComponent, B3droomComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    StateModule,
    ModelModule,
    PipesModule
  ],
  providers: [ComponentService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
