var buildUrl = "http://swopt.com:4200/assets/unity/Build";
    var loaderUrl = buildUrl + "/build.web.loader.js";
    var config = {
      dataUrl: buildUrl + "/build.web.data",
      frameworkUrl: buildUrl + "/build.web.framework.js",
      codeUrl: buildUrl + "/build.web.wasm",
      streamingAssetsUrl: "StreamingAssets",
      companyName: "Borneo3D",
      productName: "B3DROOM",
      productVersion: "1.0",
    };

    var container = document.querySelector("#unity-container");
    var canvas = document.querySelector("#unity-canvas");
    var loadingBar = document.querySelector("#unity-loading-bar");
    var progressBarFull = document.querySelector("#unity-progress-bar-full");
    var fullscreenButton = document.querySelector("#unity-fullscreen-button");
    var endButton = document.querySelector("#unity-end-button");

    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
      container.className = "unity-mobile";
      config.devicePixelRatio = 1;
    } else {
      canvas.style.width = "960px";
      canvas.style.height = "600px";
    }
    loadingBar.style.display = "block";

    var script = document.createElement("script");
    script.src = loaderUrl;
    script.onload = () => {
      createUnityInstance(canvas, config, (progress) => {
        progressBarFull.style.width = 100 * progress + "%";
        if(progress>0.05) progressBarFull.style.color = "#000000";
      }).then((unityInstance) => {
        loadingBar.style.display = "none";
        fullscreenButton.onclick = () => {
          unityInstance.SetFullscreen(1);
        };
        endButton.onclick = () => {
          unityInstance.Quit(()=>{});
        };
      }).catch((message) => {
        alert(message);
      });
    };
    document.body.appendChild(script);