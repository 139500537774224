import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';
declare var require: any;

@Component({
  selector: 'b3droom',
  templateUrl: './b3droom.component.html',
  styleUrls: ['./b3droom.component.scss']
})
export class B3droomComponent extends BaseComponent implements OnInit {
  unityInstance: any;

  constructor() { super() }

  ngOnInit(): void {
    super.ngOnInit();
    require('../../assets/unity/unity.js');
  }

  ngAfterViewInit() {
  }

  reload() {}

}
