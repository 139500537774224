import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { B3droomComponent } from './b3droom/b3droom.component';
import { ModelComponent } from './model/model.component';

const routes: Routes = [
  {path:'', redirectTo: '/model', pathMatch: 'full'},
  {path:'b3droom', component: B3droomComponent},
  {path:'model', component: ModelComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
