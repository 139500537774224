import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ChangeLanguage } from './labels.actions';
import { LangPacks } from './lang.packs/lang.packs';


const  defaultValues: LabelsStateModel = {
    lang: 'en_US',
    labels: LangPacks.labels.en_US
};

@State<LabelsStateModel>({
    name: 'labels',
    defaults: defaultValues
})
@Injectable()
export class LabelsState {
    @Selector()
    static lang(state: LabelsStateModel) {return state.lang}

    @Selector()
    static labels(state: LabelsStateModel) {
        return state.labels;
    }

    ngxsOnInit(ctx: StateContext<LabelsStateModel>) {
        let locale: string = navigator.language.replace('-','_');
        if (locale.startsWith('en')) locale = 'en_US';
        if (locale.startsWith('zh')) locale = 'zh_Hans';
        if (locale.startsWith('ms')) locale = 'ms';
        ctx.dispatch(new ChangeLanguage(locale));
    }

    @Action(ChangeLanguage)
    changeLanguage(ctx:StateContext<LabelsStateModel>, {lang}: ChangeLanguage) {
        ctx.patchState({lang: lang, labels: LangPacks.labels[lang].default});
    }
}

export interface LabelsStateModel{
    lang: string;
    labels: any;
}