import { Injectable } from "@angular/core";
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { LabelsState } from '../labels/labels.state';

@Injectable()
export class ComponentService {
    @Select(LabelsState.lang) langObv: Observable<string>;
    @Select(LabelsState.labels) labelsObv: Observable<any>;
    private activeLanguage: string = 'en_US';
    private labels = {};

    constructor() {
        this.langObv.subscribe(lang => {
          this.activeLanguage = lang
        });
        this.labelsObv.subscribe(labels => {
          this.labels = labels;
        });
    }

    groupList(list: any[], key) {
        const map = new Map();
        return list.reduce((r,a) => {
          r[a[key]] = r[a[key]] || [];
          r[a[key]].push(a);
          return r
        }, Object.create(null));
      }
    
    groupStringList(list: string[]) {
      return list.reduce((r,a) => {
        r[a] = r[a] || [];
        r[a].push(a);
        return r;
      }, Object.create(null));
    }

    getActiveLanguage() {return this.activeLanguage}

    getLabel(key: string): string {
      return this.labels[key];
    }
}