import { NgModule } from "@angular/core";
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsModule } from '@ngxs/store';
import { LabelsState } from './labels/labels.state';

@NgModule({
    imports: [
        NgxsModule.forRoot([
            LabelsState
        ]),
        //NgxsLoggerPluginModule.forRoot()
    ]
})
export class StateModule {}