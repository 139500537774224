<mat-grid-list id="desktop" cols="5" rowHeight="90vh">
    <mat-grid-tile>
        <div class="models">
            <button mat-button style="display: block; font-size: large;" (click)="grouped = models">{{'all' | tr:'All'}}</button>
            <button mat-button style="display: block; font-size: large;" 
                *ngFor="let cat of categories"
                (click)="groupByCategory(cat)">
                {{cat | tr}}</button>
        </div>
    </mat-grid-tile>
    <mat-grid-tile>
        <div class="models">
            <button mat-button style="display: block;" *ngFor='let model of grouped' title="{{getName(model)}}" (click)="changeModel(model)">
                <div id="modelThumb" style="background-image: url('{{modelpicUri}}?model={{model.uri}}');">&nbsp;</div>
                <div>{{getName(model)}}</div>
            </button>
        </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="3">
        <model-viewer class="viewer" src="{{modelsUri}}?model={{activeModel.uri}}.glb" auto-rotate camera-controls></model-viewer>
    </mat-grid-tile>
</mat-grid-list>

<mat-grid-list id="mobile" cols="3" rowHeight="fit" style="height:90vh">
    <mat-grid-tile [colspan]="3" [rowspan]="2">
        <model-viewer class="viewer" src="{{modelsUri}}?model={{activeModel.uri}}.glb" auto-rotate camera-controls></model-viewer>
    </mat-grid-tile>
    <mat-grid-tile>
        <div class="models">
            <button mat-button style="display: block; font-size: large;" (click)="grouped = models">{{'all' | tr : 'All'}}</button>
            <button mat-button style="display: block; font-size: large;" 
                *ngFor="let cat of categories"
                (click)="groupByCategory(cat)">
                {{cat | tr}}</button>
        </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="2">
        <div class="models">
            <button mat-button *ngFor="let model of grouped" title="{{getName(model)}}" (click)="changeModel(model)">
                <div id="modelThumb" style="background-image: url('{{modelpicUri}}?model={{model.uri}}');">&nbsp;</div>
            </button>
        </div>
    </mat-grid-tile>

</mat-grid-list>