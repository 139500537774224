<mat-toolbar color="primary">
  <span>Borneo3D</span>
  <div style="width:100%;text-align: right">
    <div style="display: inline;">
      <div *ngIf="router.url !== '/b3droom'" class="visitShowroom">
        <span class="text">{{('visitShowroom'| tr : 'click B3DROOM below to visit our virtual showroom').toUpperCase()}}</span><br/>
        <span class="footnote">{{'mobileNoSupport'|tr:'**mobile devices temporarily not supported'}}</span>
      </div>
      <button *ngIf="router.url !== '/b3droom'" mat-button [routerLink]="['/b3droom']">B3DROOM</button>
    </div>
    <button mat-icon-button [matMenuTriggerFor]="lang"><mat-icon>language</mat-icon></button>  
    <mat-menu #lang="matMenu">
      <button *ngFor="let lang of langs" mat-menu-item (click)="changeLang(lang.key)">{{lang.value}}</button>
    </mat-menu>
    <button mat-icon-button (click)="openWindow('https://www.facebook.com/borneo3d/')"><img style="width: 30px" src="https://www.iconfinder.com/data/icons/free-social-icons/67/facebook_circle_gray-512.png"/></button>
  </div>
</mat-toolbar>
<div class="bg">
  <div class="content">
    <div #tagline>
      <span class="tagline">{{'tagline'|tr:tagline}}</span>
      <div class="simpleDesc">{{'homeDesc'|tr:homeDesc}}</div>
    </div>

  </div>
  <div style="padding-top:10px; width:100%">
    <router-outlet></router-outlet>
  </div>
</div>
<footer>© {{year}} Borneo3D {{'all.rights.reserved'|tr:'All Rights Reserved'}}</footer>