import { Component, OnInit } from '@angular/core';
import { Model } from './model.interface';
import '@google/model-viewer';
import { BaseComponent } from '../base.component';
import * as modelDefs from './models.json';
import { ComponentService } from '../service/component.service';
import { CssSelector } from '@angular/compiler';
import { URLs } from '../domain/domain.config';

@Component({
  selector: 'model',
  templateUrl: './model.component.html',
  styleUrls: ['./model.component.scss']
})
export class ModelComponent extends BaseComponent implements OnInit {
  //@ts-ignore
  models: Model[] = modelDefs.default;
  activeModel = this.models.find(x => x.id === 'kch.gov');
  categories = [];
  grouped = this.models;
  modelsUri = URLs.MODELS;
  modelpicUri = URLs.MODELPIC;

  constructor(private cs: ComponentService) { super() }

  ngOnInit(): void {
    super.ngOnInit();
    this.models.forEach(model => {
      model.categories.forEach(category => {this.categories.push(category)})
    });
    this.categories = Object.keys(this.cs.groupStringList(this.categories)).sort();
    console.log(this.categories);
  }


  changeModel(model: Model) {
    this.activeModel = this.models.find(x => x.id === model.id);
  }

  getName(model: Model): string {
    let name = model.name[this.cs.getActiveLanguage()];
    return name?name:model.name.default;
  }

  groupByCategory(category) {
    this.grouped = this.models.filter(x => x.categories.indexOf(category) !== -1);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  cssImage(model: Model) {
    return `backgroud: url('`+model.uri+model.thumbFmt+`') no-repeat;height:100px`
  }

  

}
