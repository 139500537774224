import { Component, Directive } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { LabelsState } from './labels/labels.state';

@Directive()
export class BaseComponent {
    private currLabels;
    private lblsSubscription: Subscription;
    @Select(LabelsState.labels) private labels: Observable<any>;

    ngOnInit() {
        this.lblsSubscription = this.labels.subscribe(lbls => {
            this.currLabels = lbls;
        });
    }

    getLabel(key, defVal?): string {
        let label = this.currLabels[key];
        return label?label: defVal;
    }

    ngOnDestroy() {
        this.lblsSubscription.unsubscribe();
    }
}