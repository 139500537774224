import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModelComponent } from './model.component';
import { MaterialModule } from '../material.module';
import { ComponentService } from '../service/component.service';
import { TranslatePipe } from '../labels/translate.pipe';
import { PipesModule } from '../pipes.module';



@NgModule({
  declarations: [ModelComponent],
  imports: [
    CommonModule, MaterialModule, PipesModule
  ],
  providers: [ComponentService],
  exports: [ModelComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ModelModule { }
